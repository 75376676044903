import React, { useEffect, useState } from 'react'

import type { Star } from '../../../components/Modals/SessionRatingModal'
import { StarIcon } from '@heroicons/react/solid'
import { useRateResource } from '../../../mutations/resources/RateResource'
import { useToastContext } from '../../../contexts/ToastContext'
import trackMixPanel, { MIXPANEL_EVENT } from '../../../hooks/useMixPanel'
import { useAuth } from '../../../contexts/AuthProvider'
import type { PreviewResource } from '../../../types/Resources'
import type { PreviewCourse } from '../../../types/Courses'

const stars: Star[] = [
  { value: 5 },
  { value: 4 },
  { value: 3 },
  { value: 2 },
  { value: 1 },
]

const StarsRating: React.FC<{
  resource: PreviewResource | PreviewCourse
  title: string
  resourceId: string
  resourceTitle: string
}> = ({ resource, title, resourceId, resourceTitle }) => {
  const user = useAuth()
  const addToast = useToastContext()
  const { mutate: callRateResource } = useRateResource()

  const [rating, setRating] = useState<number>(0)

  const rateResource = (value: number) => {
    setRating(value)
    callRateResource(
      { resourceId, value },
      {
        onSuccess: () => {
          addToast('success', 'Resource rated successfully!')
          trackMixPanel({
            eventName: MIXPANEL_EVENT.RESOURCE_RATED,
            properties: {
              type: resource.type,
              serviceLine: resource.serviceLines,
              audiences: resource.audiences,
              topics: resource.topics,
              id: resourceId,
              name: resourceTitle,
              accountID: user.user.data.id,
              rating: value,
            },
          })
        },
        onError: () => addToast('error', 'Something went wrong.'),
      }
    )
  }

  useEffect(() => {
    setRating(0)
  }, [resourceId])

  return (
    <div className="flex w-full flex-col items-center gap-4">
      <p className="text-base font-semibold">{title}</p>
      <div className="flex flex-col items-center gap-3">
        <div className="star-rating flex flex-row-reverse gap-1">
          {stars.map((star: Star) => (
            <StarIcon
              key={star.value}
              className={`${
                rating >= star.value
                  ? 'text-status-warning'
                  : 'text-components-disabled'
              } star h-10 w-10 cursor-pointer`}
              onClick={() => rateResource(star.value)}
            />
          ))}
        </div>
      </div>
    </div>
  )
}

export default StarsRating
