import {
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ExclamationCircleIcon,
  RefreshIcon,
} from '@heroicons/react/solid'
import { yupResolver } from '@hookform/resolvers/yup'
import type { KeyboardEvent } from 'react'
import React, { Fragment, useEffect, useState } from 'react'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom'
import * as Yup from 'yup'
import { useController, useForm } from 'react-hook-form'
import { Listbox, Transition } from '@headlessui/react'
import cloneDeep from 'lodash.clonedeep'
import { flushSync } from 'react-dom'
import mixpanel from 'mixpanel-browser'

import {
  comboBoxClass,
  comboBoxErrorClass,
  inputErrorClass,
  inputValidClass,
  primaryButtonClass,
  tertiaryButtonClass,
} from '../../constants/classConstants'
import { DEFAULT_DATE_REGEX } from '../../constants/regex'
import {
  genderIdentities,
  genderOptions,
  languages,
  relationshipOptionsProfile,
  states,
  preferredPronouns as preferredPronounsArray,
  raceOptions,
} from '../../constants/values'
import type { GenderIdentity, Profile, Race } from '../../types/Profile'
import { usePatient } from '../../contexts/PatientProvider'
import { useAuth } from '../../contexts/AuthProvider'
import DatePicker from '../../components/DatePicker'
import type { State } from '../../types/State'
import {
  ONBOARDING_STEP,
  type Patient,
  type RelationshipType,
} from '../../types/Patient'
import Stepper from '../../components/Stepper'
import {
  calculateAge,
  frontendToBackendTz,
  backendToFrontendTz,
  getTimeZones,
  isValidDefaultDate,
  getAgeFromBirthdate,
  convertFrontendDateToBackend,
} from '../../helpers/generic'
import SkipAndComeBackLater from '../../components/SkipAndComeBackLater'
import type { InterestOption } from './WhatBringsYouHere'
import { useUpdateProfile } from '../../mutations/dashboard/UpdateProfile'
import { useCheckPatientDOB } from '../../mutations/onboarding/CheckPatientDOB'
import { useSaveContactInfo } from '../../mutations/eligibility/SaveContactInvalidVerification'
import { useSendAfterOnboardingEmail } from '../../mutations/emails/SendAfterOnboardingEmail'
import { useClaimPerson } from '../../mutations/eligibility/ClaimPerson'
import { auth } from '../../config/firebase'
import trackMixPanel, { MIXPANEL_EVENT } from '../../hooks/useMixPanel'
import { initMixpanel } from '../../helpers/mixpanel'

initMixpanel()

const MIXPANEL_DATA = {
  eventName: MIXPANEL_EVENT.ONBOARDING_STEP_COMPLETED,
  properties: { step: ONBOARDING_STEP.buildProfile },
}

const formSchema = (forSelf: boolean, defaultBirthDate: boolean) =>
  Yup.object().shape({
    firstName: Yup.string().required('First name is required.').trim(),
    lastName: Yup.string().required('Last name is required.').trim(),
    preferredName: Yup.string().nullable(),
    birthDate: Yup.string()
      .required('Birthdate is required.')
      .matches(
        DEFAULT_DATE_REGEX,
        'Birthdate must be in valid MM/DD/YYYY format.'
      )
      .test('valid-date', 'Birthdate is not valid.', (enteredDate: string) =>
        isValidDefaultDate(enteredDate)
      )
      .test(
        'test-birth-date-future',
        'Birthdate cannot be in the future.',
        (enteredDate: string) => {
          const date = new Date(enteredDate)
          const now = new Date()
          return date <= now
        }
      )
      .test('test-birth-date-age', 'patientAgeError', (enteredDate: string) => {
        if (defaultBirthDate) return true
        const age = calculateAge(new Date(enteredDate))
        return forSelf ? age >= 18 : age < 18
      })
      .trim(),
    gender: Yup.string().required('Sex at birth is required.').trim(),
    preferredPronouns: Yup.string().nullable(),
    genderIdentity: Yup.mixed().required('Gender identity is required.'),
    preferredLanguage: Yup.string()
      .required('Preferred language is required.')
      .trim(),
    race: Yup.mixed().nullable(),
    state: Yup.string().required('State is required.').trim(),
    relationship: Yup.mixed().required('Relationship is required.'),
    timeZone: Yup.string().required('Time zone is required.').trim(),
  })

const BuildProfileScreen: React.FC = () => {
  const [searchParams] = useSearchParams()
  const location = useLocation()
  const { user, setUser } = useAuth()
  const navigate = useNavigate()
  const { setPatient, patient } = usePatient()
  const [dobErrorCounter, setDobErrorCounter] = useState(0)
  const [dobErrorUnder18, setDobErrorUnder18] = useState(false)

  const isForSelfFromUrl = Boolean(searchParams.get('forSelf')?.length)
  const patientIdFromUrl = searchParams?.get('patientId')
  const personIdFromUrl = searchParams?.get('personId')
  const signUpFromEmail = searchParams?.get('signUpFromEmail') == 'true'
  const forSelf: boolean = isForSelfFromUrl
    ? searchParams.get('forSelf') === 'true'
    : patient?.relationship?.key === 'myself'

  const { mutateAsync: callUpdateProfile, isLoading: isLoadingUpdateProfile } =
    useUpdateProfile()
  const { mutateAsync: callCheckPatientDOB, isLoading: isLoadingCheckingDOB } =
    useCheckPatientDOB()
  const {
    mutateAsync: callSendSupportEmail,
    isLoading: isLoadingSupportEmail,
  } = useSaveContactInfo()
  const { mutateAsync: callSendAfterOnboardingEmail } =
    useSendAfterOnboardingEmail()
  const { mutateAsync: callClaimPerson, isLoading: isLoadingClaimCustomer } =
    useClaimPerson()

  const isInterestedInTherapy: boolean =
    user.data.whatBringsYouHere.some(
      (o: InterestOption) => o?.name === 'Therapy'
    ) || patient?.allowedToBook

  // prefill with the patient that is onboarded a 2nd time, or it's a separate login or it's a sign up from email
  const patientWithProfile = user.roster.find(
    (p) =>
      p.id === patientIdFromUrl &&
      (Boolean(p.gender) || p.isSeparateLogin || signUpFromEmail)
  )

  const personId =
    personIdFromUrl ||
    user.roster.find((p) => p.id === patientIdFromUrl)?.personId ||
    patient?.personId

  const disabledAllFields =
    Boolean(patientWithProfile) &&
    !patientWithProfile.isSeparateLogin &&
    !signUpFromEmail &&
    !patientWithProfile?.relationship?.key

  const getPatientValues = (patientData: any): Profile => {
    const state = states.find(
      (s: State) => s.name === patientData?.state
    )?.abbrev

    return {
      firstName: patientData?.firstName,
      lastName: patientData?.lastName,
      preferredName: patientData?.preferredName,
      birthDate:
        searchParams.get('birthDate') && !patientData?.isSeparateLogin
          ? patientData?.birthDate
          : '',
      gender: state && patientData?.gender ? patientData?.gender : '',
      genderIdentity:
        state && patientData?.genderIdentity ? patientData?.genderIdentity : '',
      preferredLanguage: patientData?.preferredLanguage,
      relationship: patientData?.relationship,
      state,
      preferredPronouns: patientData?.preferredPronouns,
      timeZone: frontendToBackendTz(patientData?.timeZone),
      race: patientData?.race,
    }
  }

  const getFormDefaultValues = () => {
    if (patientWithProfile)
      return {
        ...getPatientValues(patientWithProfile),
        relationship: forSelf
          ? relationshipOptionsProfile.find((r) => r.key === 'myself')
          : patientWithProfile?.relationship,
        preferredLanguage:
          signUpFromEmail && !patientWithProfile?.relationship?.key
            ? ''
            : patientWithProfile?.preferredLanguage || 'English',
      }

    if (forSelf)
      return {
        ...getPatientValues(patient),
        firstName: user.data.firstName,
        lastName: user.data.lastName,
        relationship: relationshipOptionsProfile.find(
          (r) => r.key === 'myself'
        ),
      }

    if (user.data.clientData.clientType === 'EDUCATION')
      return getPatientValues(patient)
  }

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    control,
    setValue,
    reset,
    watch,
  } = useForm<Profile>({
    defaultValues: getFormDefaultValues(),
    mode: 'all',
    resolver: yupResolver(
      formSchema(
        forSelf,
        Boolean(
          searchParams.get('birthDate')?.length ||
            patient?.birthDate ||
            patientWithProfile?.birthDate
        )
      )
    ),
  })

  // listen to form changes of 'Relationship' field
  const forSelfFromForm = watch('relationship')?.key === 'myself'

  // check for dob error under 18 and relationship change in realtime
  useEffect(() => {
    if (errors.birthDate) return

    const birthDateValue = watch('birthDate')
    const age = getAgeFromBirthdate(birthDateValue)

    if (forSelfFromForm && age < 18) return setDobErrorUnder18(true)

    if (dobErrorUnder18) setDobErrorUnder18(false)
  }, [watch('birthDate'), watch('relationship'), errors.birthDate])

  // reset the form for different patient id in url
  useEffect(() => {
    reset(getFormDefaultValues())
    setDobErrorCounter(0)
  }, [patientIdFromUrl, reset])

  const { field: fieldSexAtBirth } = useController({
    control: control,
    name: 'gender',
    defaultValue: '',
  })

  const { field: fieldGenderIdentity } = useController({
    control: control,
    name: 'genderIdentity',
    defaultValue: null,
  })

  const { field: fieldPreferredPronouns } = useController({
    control: control,
    name: 'preferredPronouns',
    defaultValue: '',
  })

  const { field: fieldLanguage } = useController({
    control: control,
    name: 'preferredLanguage',
    defaultValue: '',
  })

  const { field: fieldRelationship } = useController({
    control: control,
    name: 'relationship',
    defaultValue: null,
  })

  const { field: fieldRace } = useController({
    control: control,
    name: 'race',
    defaultValue: null,
  })

  const { field: fieldState } = useController({
    control: control,
    name: 'state',
    defaultValue: '',
  })

  const { field: fieldTimeZone } = useController({
    control: control,
    name: 'timeZone',
    defaultValue: '',
  })

  const addPatientToMixPanel = (currentPatient: Partial<Patient>) => {
    mixpanel.people.append(
      'patients',
      currentPatient?.firstName + ' ' + currentPatient?.lastName
    )
  }

  const submit = async (profile: Profile) => {
    const currentPatient: Partial<Patient> = {
      ...profile,
      id: patient?.id || patientIdFromUrl || patientWithProfile?.id,
      timeZone: backendToFrontendTz(profile.timeZone),
      age: getAgeFromBirthdate(profile.birthDate),
      conditions: [],
      studentId:
        searchParams.get('externalId') ||
        patient?.studentId ||
        patient?.externalId ||
        patientWithProfile?.studentId ||
        patientWithProfile?.externalId ||
        '',
      locationId: searchParams.get('locationId') || '',
      email: searchParams.get('email') || forSelf ? user.data.email : null,
      personId,
    }

    // check DOB against backend
    const isDobCorrect: boolean = await callCheckPatientDOB({
      personId,
      dob: convertFrontendDateToBackend(currentPatient.birthDate),
    })

    if (dobErrorCounter == 2) {
      // append new query params errorDob=true among the rest of the query params
      const urlParams = new URLSearchParams(location.search)
      urlParams.set('errorDob', 'true')
      const queryParams = urlParams.toString()
      callSendSupportEmail({
        clientName: user.data.clientData.clientName,
        email: user.data.email,
        birthDate: currentPatient.birthDate,
        externalId: currentPatient.id,
        firstName: currentPatient.firstName,
        lastName: currentPatient.lastName,
        phoneNumber: user.data.phoneNumber,
      })
      return navigate(`/onboarding/build-profile?${queryParams}`)
    }

    if (!isDobCorrect) return setDobErrorCounter(dobErrorCounter + 1)

    const isForSelf = profile.relationship?.key === 'myself'

    // claim person if relationship is myself
    if (isForSelf) {
      const bearerToken = await auth.currentUser.getIdToken()
      await callClaimPerson({
        bearerToken,
        clientId: user.data.clientId,
        dateOfBirth: profile.birthDate,
        externalId: currentPatient?.studentId || currentPatient?.externalId,
      })
    }

    // send after onboarding email
    callSendAfterOnboardingEmail({
      clientId: user.data.clientId,
      forSelf: patient?.relationship?.key === 'myself',
    })

    const newlyResultedPatient = await callUpdateProfile({
      patient: currentPatient,
      user,
    })

    const hasEmergencyContact = Boolean(
      newlyResultedPatient?.emergencyContact?.firstName
    )

    const newPatient = {
      ...newlyResultedPatient,
      relationship: profile.relationship,
      preferredLanguage: profile.preferredLanguage,
      onboardingStep: hasEmergencyContact
        ? ONBOARDING_STEP.interest
        : ONBOARDING_STEP.emergencyContact,
    }
    const newUser = cloneDeep(user)
    if (isForSelf) newUser.data.personId = newPatient.personId
    const patientIndex = newUser.roster.findIndex(
      (p: Patient) => p.id === newPatient.id
    )
    if (patientIndex === -1) newUser.roster.push(newPatient)
    else newUser.roster[patientIndex] = newPatient

    addPatientToMixPanel(newPatient)

    trackMixPanel({
      eventName: MIXPANEL_DATA.eventName,
      properties: {
        ...MIXPANEL_DATA.properties,
        patient: newPatient?.firstName + ' ' + newPatient?.lastName,
        additionalUser: 'Yes',
      },
    })

    flushSync(() => {
      setUser(newUser)
      setPatient(newPatient)
      if (hasEmergencyContact)
        navigate(`/onboarding/interest${location.search}`)
      else navigate(`/onboarding/emergency-contact${location.search}`)
    })
  }

  const goBack = () => {
    if (location?.state?.from) navigate(location.state.from)
    else navigate(-1)
  }

  const handleClickSkip = () => {
    if (user.roster.filter((p) => p.onboardingStep !== null).length > 1)
      navigate('/onboarding/add-another-person')
    else navigate('/dashboard')
  }

  const classNames = (...classes: string[]) => classes.filter(Boolean).join(' ')

  const isLoading =
    isLoadingUpdateProfile ||
    isLoadingCheckingDOB ||
    isLoadingSupportEmail ||
    isLoadingClaimCustomer

  return (
    <div className="max-w-xl">
      <div className="mx-auto">
        <Stepper
          steps={
            isInterestedInTherapy &&
            user.data.clientData.clientType !== 'EDUCATION'
              ? [
                  { name: '1', status: 'current' },
                  { name: '2', status: '' },
                  { name: '3', status: '' },
                  { name: '4', status: '' },
                ]
              : [
                  { name: '1', status: 'current' },
                  { name: '2', status: '' },
                  { name: '3', status: '' },
                ]
          }
        />
      </div>

      {/* Header */}
      <div className="flex flex-col items-center justify-center gap-6 xs:gap-2">
        {/* Title */}
        <p className="text-center text-2xl font-semibold text-text-primary xs:text-base">
          Let's build {forSelf || forSelfFromForm ? 'your' : 'their'} profile!
        </p>

        {/* Subtitle */}
        {disabledAllFields && (
          <p className="whitespace-pre-wrap text-center text-base xs:text-sm">
            This profile was created by another account holder.{'\n'}You can
            modify this information in My Roster {'>'} Patient Profile.
          </p>
        )}
      </div>

      <div className="grid grid-cols-2 gap-4">
        {/* First name */}
        <div className="xs:col-span-2">
          <label
            htmlFor="firstName"
            className="block text-base font-semibold text-text-primary xs:text-sm"
          >
            First name
          </label>
          <div className="relative mt-1">
            <input
              type="text"
              className={errors.firstName ? inputErrorClass : inputValidClass}
              disabled={
                forSelf ||
                Boolean(patientWithProfile) ||
                disabledAllFields ||
                (Boolean(searchParams.get('firstName')?.length) &&
                  user.data.clientData.clientType === 'EDUCATION')
              }
              placeholder="First name"
              {...register('firstName')}
            />
            {errors.firstName && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          <p className="mt-2 text-sm text-status-error">
            {errors.firstName?.message}
          </p>
        </div>
        {/* Last name */}
        <div className="xs:col-span-2">
          <label
            htmlFor="lastName"
            className="block text-base font-semibold text-text-primary xs:text-sm"
          >
            Last name
          </label>
          <div className="relative mt-1">
            <input
              type="text"
              className={errors.lastName ? inputErrorClass : inputValidClass}
              disabled={
                forSelf ||
                Boolean(patientWithProfile) ||
                disabledAllFields ||
                (Boolean(searchParams.get('lastName')?.length) &&
                  user.data.clientData.clientType === 'EDUCATION')
              }
              placeholder="Last name"
              {...register('lastName')}
            />
            {errors.lastName && (
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
          <p className="mt-2 text-sm text-status-error">
            {errors.lastName?.message}
          </p>
        </div>
        {/* Preferred name (optional) */}
        <div className="xs:col-span-2">
          <label
            htmlFor="preferredName"
            className="block text-base font-semibold text-text-primary xs:text-sm"
          >
            Preferred name (optional)
          </label>
          {disabledAllFields && !patientWithProfile.preferredName ? (
            <p className="py-3.5 pl-4 xs:text-sm">-</p>
          ) : (
            <div className="relative mt-1">
              <input
                type="text"
                disabled={disabledAllFields}
                className={
                  errors.preferredName ? inputErrorClass : inputValidClass
                }
                placeholder="Preferred name"
                {...register('preferredName')}
              />
              {errors.preferredName && (
                <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                  <ExclamationCircleIcon
                    className="h-5 w-5 text-red-500"
                    aria-hidden="true"
                  />
                </div>
              )}
            </div>
          )}
          <p className="mt-2 text-sm text-status-error">
            {errors.preferredName?.message}
          </p>
        </div>
        {/* Date of birth */}
        <div className="xs:col-span-2">
          <label
            htmlFor="birthDate"
            className="block text-base font-semibold text-text-primary xs:text-sm"
          >
            Date of birth
          </label>
          <div className="relative mt-1">
            <DatePicker
              setValue={setValue}
              control={control}
              fieldKey="birthDate"
              isDisabled={
                (disabledAllFields && Boolean(patientWithProfile?.birthDate)) ||
                Boolean(searchParams.get('birthDate'))
              }
            />
          </div>
          {errors.birthDate && (
            <p className="mt-2 text-sm text-status-error">
              {errors.birthDate.message === 'patientAgeError'
                ? forSelf
                  ? 'Those who are younger than 18 must have their parent/guardian create their account.'
                  : 'Patients who are 18 and older must create their own account.'
                : errors.birthDate.message}
            </p>
          )}
        </div>
        {/* Sex at birth */}
        <div className="xs:col-span-2">
          <Listbox {...fieldSexAtBirth} disabled={disabledAllFields}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-base font-semibold text-text-primary xs:text-sm">
                  Sex at birth
                </Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button
                    onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                      const match = genderOptions.find((g: string) =>
                        g.toLowerCase().startsWith(e.key.toLowerCase())
                      )
                      if (match) {
                        setValue('gender', match, {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: true,
                        })
                      }
                    }}
                    className={
                      errors.gender ? comboBoxErrorClass : comboBoxClass
                    }
                  >
                    <span
                      className={`${
                        fieldSexAtBirth?.value ? '' : 'text-text-placeholder'
                      } block truncate`}
                    >
                      {fieldSexAtBirth?.value || 'Select'}
                    </span>
                    {!disabledAllFields && (
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {genderOptions.map((gender: string) => (
                        <Listbox.Option
                          key={gender}
                          className={({ active }) =>
                            classNames(
                              'relative cursor-pointer select-none p-4 py-2 text-text-primary xs:text-sm',
                              active && 'bg-components-fillBorders'
                            )
                          }
                          value={gender}
                        >
                          <span className={'block truncate'}>{gender}</span>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          {errors.gender && (
            <p className="mt-2 text-sm text-status-error">
              {errors.gender.message}
            </p>
          )}
        </div>
        {/* Gender identity */}
        <div className="xs:col-span-2">
          <Listbox {...fieldGenderIdentity} disabled={disabledAllFields}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-base font-semibold text-text-primary xs:text-sm">
                  Gender identity
                </Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button
                    onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                      const match = genderIdentities.find((g: GenderIdentity) =>
                        g.displayName
                          .toLowerCase()
                          .startsWith(e.key.toLowerCase())
                      )
                      if (match) {
                        setValue('genderIdentity', match, {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: true,
                        })
                      }
                    }}
                    className={
                      errors.genderIdentity ? comboBoxErrorClass : comboBoxClass
                    }
                  >
                    <span
                      className={`${
                        fieldGenderIdentity?.value
                          ? ''
                          : 'text-text-placeholder'
                      } block truncate`}
                    >
                      {fieldGenderIdentity?.value?.displayName || 'Select'}
                    </span>
                    {!disabledAllFields && (
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {genderIdentities.map((gender: GenderIdentity) => (
                        <Listbox.Option
                          key={gender?.key}
                          className={({ active }) =>
                            classNames(
                              'relative cursor-pointer select-none p-4 py-2 text-text-primary xs:text-sm',
                              active && 'bg-components-fillBorders'
                            )
                          }
                          value={gender}
                        >
                          <span className={'block truncate'}>
                            {gender.displayName}
                          </span>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          {errors.genderIdentity && (
            <p className="mt-2 text-sm text-status-error">
              {errors.genderIdentity.message}
            </p>
          )}
        </div>
        {/* Preferred pronouns (optional) */}
        <div className="xs:col-span-2">
          <Listbox {...fieldPreferredPronouns} disabled={disabledAllFields}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-base font-semibold text-text-primary xs:text-sm">
                  Pronouns (optional)
                </Listbox.Label>
                <div className="relative mt-1">
                  {disabledAllFields &&
                  !patientWithProfile.preferredPronouns ? (
                    <p className="py-3.5 pl-4 xs:text-sm">-</p>
                  ) : (
                    <Listbox.Button
                      onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                        const match = preferredPronounsArray
                          .filter((p) => p)
                          .find((p: string) =>
                            p.toLowerCase().startsWith(e.key.toLowerCase())
                          )
                        if (match) {
                          setValue('preferredPronouns', match, {
                            shouldDirty: true,
                            shouldTouch: true,
                            shouldValidate: true,
                          })
                        }
                      }}
                      className={
                        errors.preferredPronouns
                          ? comboBoxErrorClass
                          : comboBoxClass
                      }
                    >
                      <span
                        className={`${
                          fieldPreferredPronouns?.value
                            ? ''
                            : 'text-text-placeholder'
                        } block truncate`}
                      >
                        {fieldPreferredPronouns?.value || 'Select'}
                      </span>
                      {!disabledAllFields && (
                        <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                          <ChevronDownIcon
                            className="h-5 w-5 text-gray-400"
                            aria-hidden="true"
                          />
                        </span>
                      )}
                    </Listbox.Button>
                  )}

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {preferredPronounsArray.map((pronouns: string) => (
                        <Listbox.Option
                          key={pronouns}
                          className={({ active }) =>
                            classNames(
                              'relative cursor-pointer select-none p-4 py-2 text-text-primary xs:text-sm',
                              active && 'bg-components-fillBorders'
                            )
                          }
                          value={pronouns}
                        >
                          <span className={'block truncate'}>
                            {pronouns || '-'}
                          </span>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          {errors.preferredPronouns && (
            <p className="mt-2 text-sm text-status-error">
              {errors.preferredPronouns.message}
            </p>
          )}
        </div>
        {/* Preferred language */}
        <div className="xs:col-span-2">
          <Listbox {...fieldLanguage} disabled={disabledAllFields}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-base font-semibold text-text-primary xs:text-sm">
                  Preferred language
                </Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button
                    onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                      const match = languages.find((l: string) =>
                        l.toLowerCase().startsWith(e.key.toLowerCase())
                      )
                      if (match) {
                        setValue('preferredLanguage', match, {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: true,
                        })
                      }
                    }}
                    className={
                      errors.preferredLanguage
                        ? comboBoxErrorClass
                        : comboBoxClass
                    }
                  >
                    <span
                      className={`${
                        fieldLanguage?.value ? '' : 'text-text-placeholder'
                      } block truncate`}
                    >
                      {fieldLanguage?.value || 'Select'}
                    </span>
                    {!disabledAllFields && (
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {languages.map((language: string) => (
                        <Listbox.Option
                          key={language}
                          className={({ active }) =>
                            classNames(
                              'relative cursor-pointer select-none p-4 py-2 text-text-primary xs:text-sm',
                              active && 'bg-components-fillBorders'
                            )
                          }
                          value={language}
                        >
                          <span className={'block truncate'}>{language}</span>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          {errors.preferredLanguage && (
            <p className="mt-2 text-sm text-status-error">
              {errors.preferredLanguage.message}
            </p>
          )}
        </div>
        {/* Race */}
        <div className="xs:col-span-2">
          <p className="text-base font-semibold xs:text-sm">
            Race & Ethnicity (optional)
          </p>
          <Listbox {...fieldRace} disabled={disabledAllFields}>
            {({ open }) => (
              <div className="relative mt-1">
                {disabledAllFields && !patientWithProfile.race ? (
                  <p className="py-3.5 pl-4 xs:text-sm">-</p>
                ) : (
                  <Listbox.Button
                    onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                      const match = raceOptions
                        .filter((r: Race) => r.key)
                        .find((r: Race) =>
                          r.displayName
                            .toLowerCase()
                            .startsWith(e.key.toLowerCase())
                        )
                      if (match) {
                        setValue('race', match, {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: true,
                        })
                      }
                    }}
                    className={`${
                      errors.race ? comboBoxErrorClass : comboBoxClass
                    }`}
                  >
                    <span
                      className={`${
                        fieldRace?.value?.displayName
                          ? ''
                          : 'text-text-placeholder'
                      } block truncate`}
                    >
                      {fieldRace?.value?.displayName || 'Select'}
                    </span>
                    {!disabledAllFields && (
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                  </Listbox.Button>
                )}

                <Transition
                  show={open}
                  as={Fragment}
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >
                  <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                    {raceOptions.map((race: Race) => (
                      <Listbox.Option
                        key={race.key}
                        className={({ active }) =>
                          classNames(
                            'relative cursor-pointer select-none p-4 py-2 xs:text-sm',
                            active && 'bg-components-fillBorders'
                          )
                        }
                        value={race}
                      >
                        <span className="block">{race.displayName || '-'}</span>
                      </Listbox.Option>
                    ))}
                  </Listbox.Options>
                </Transition>
              </div>
            )}
          </Listbox>
        </div>
        {/* State */}
        <div className="xs:col-span-2">
          <Listbox {...fieldState} disabled={disabledAllFields}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-base font-semibold text-text-primary xs:text-sm">
                  State of residence
                </Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button
                    onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                      const match = states.find((s: State) =>
                        s?.name?.toLowerCase().startsWith(e.key.toLowerCase())
                      )
                      if (match) {
                        setValue('state', match.abbrev, {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: true,
                        })
                      }
                    }}
                    className={comboBoxClass}
                  >
                    <span
                      className={`${
                        fieldState?.value ? '' : 'text-text-placeholder'
                      } block truncate`}
                    >
                      {states?.find(
                        (s: State) => s.abbrev === fieldState?.value
                      )?.name || 'Select'}
                    </span>
                    {!disabledAllFields && (
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2 text-gray-400">
                        <ChevronDownIcon
                          className="h-5 w-5 "
                          aria-hidden="true"
                        />
                      </span>
                    )}
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {states.map((state: State) => (
                        <Listbox.Option
                          key={state.abbrev}
                          className={({ active }) =>
                            classNames(
                              'relative cursor-pointer select-none p-4 py-2 text-text-primary xs:text-sm',
                              active && 'bg-components-fillBorders'
                            )
                          }
                          value={state.abbrev}
                        >
                          <span className={'block truncate'}>
                            {state?.name}
                          </span>
                        </Listbox.Option>
                      ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
        </div>
        {/* Your relationship to this person */}
        <div className="xs:col-span-2">
          <Listbox {...fieldRelationship} disabled={forSelf}>
            {({ open }) => (
              <>
                <Listbox.Label className="block text-base font-semibold text-text-primary xs:text-sm">
                  Your relationship to this person
                </Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button
                    onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                      if (forSelf) return

                      const match = relationshipOptionsProfile
                        .slice(1, relationshipOptionsProfile?.length)
                        .find((r: RelationshipType) =>
                          r.name.toLowerCase().startsWith(e.key.toLowerCase())
                        )
                      if (match) {
                        setValue('relationship', match, {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: true,
                        })
                      }
                    }}
                    className={
                      errors.relationship ? comboBoxErrorClass : comboBoxClass
                    }
                  >
                    <span
                      className={`block truncate ${
                        !fieldRelationship?.value?.name
                          ? 'text-text-placeholder'
                          : ''
                      }`}
                    >
                      {forSelf
                        ? 'Myself'
                        : fieldRelationship?.value?.name || 'Select'}
                    </span>
                    {!forSelf && (
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {relationshipOptionsProfile
                        .filter((r) => {
                          const hasMyselfPatient = user?.roster?.some(
                            (p) => p?.relationship?.key === 'myself'
                          )
                          if (hasMyselfPatient || searchParams.get('forSelf'))
                            return r.key !== 'myself'
                          return true
                        })
                        .map((relationship: RelationshipType) => (
                          <Listbox.Option
                            key={relationship?.key}
                            className={({ active }) =>
                              classNames(
                                'relative cursor-pointer select-none p-4 py-2 text-text-primary xs:text-sm',
                                active && 'bg-components-fillBorders'
                              )
                            }
                            value={relationship}
                          >
                            <span className={'block truncate'}>
                              {relationship?.name}
                            </span>
                          </Listbox.Option>
                        ))}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          {errors.relationship && (
            <p className="mt-2 text-sm text-status-error">
              {errors.relationship.message}
            </p>
          )}
        </div>
        {/* Time zone */}
        <div className="xs:col-span-2">
          <Listbox
            {...fieldTimeZone}
            disabled={
              disabledAllFields && Boolean(patientWithProfile?.timeZone)
            }
          >
            {({ open }) => (
              <>
                <Listbox.Label className="block text-base font-semibold text-text-primary xs:text-sm">
                  Time zone
                </Listbox.Label>
                <div className="relative mt-1">
                  <Listbox.Button
                    onKeyDown={(e: KeyboardEvent<HTMLButtonElement>) => {
                      const match = Object.keys(getTimeZones()).find(
                        (tzKey: string) =>
                          tzKey.toLowerCase().startsWith(e.key.toLowerCase())
                      )
                      if (match) {
                        setValue('timeZone', match, {
                          shouldDirty: true,
                          shouldTouch: true,
                          shouldValidate: true,
                        })
                      }
                    }}
                    className={
                      errors.timeZone ? comboBoxErrorClass : comboBoxClass
                    }
                  >
                    <span
                      className={`${
                        fieldTimeZone?.value ? '' : 'text-text-placeholder'
                      } block truncate`}
                    >
                      {fieldTimeZone?.value || 'Select'}
                    </span>
                    {!disabledAllFields && (
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <ChevronDownIcon
                          className="h-5 w-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    )}
                  </Listbox.Button>

                  <Transition
                    show={open}
                    as={Fragment}
                    leave="transition ease-in duration-100"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <Listbox.Options className="absolute z-10 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                      {Object.keys(getTimeZones()).map(
                        (timeZoneKey: string) => (
                          <Listbox.Option
                            key={timeZoneKey}
                            className={({ active }) =>
                              classNames(
                                'relative cursor-pointer select-none p-4 py-2 text-text-primary xs:text-sm',
                                active && 'bg-components-fillBorders'
                              )
                            }
                            value={timeZoneKey}
                          >
                            <span className={'block truncate'}>
                              {timeZoneKey}
                            </span>
                          </Listbox.Option>
                        )
                      )}
                    </Listbox.Options>
                  </Transition>
                </div>
              </>
            )}
          </Listbox>
          {errors.timeZone && (
            <p className="mt-2 text-sm text-status-error">
              {errors.timeZone.message}
            </p>
          )}
        </div>

        {dobErrorUnder18 && (
          <ErrorMessaging text="Those who are younger than 18 must have their parent/guardian create their account." />
        )}

        {dobErrorCounter >= 3 ? (
          <p className="col-span-2 rounded-2xl bg-components-fillBorders p-4 text-sm">
            Looks like you're having trouble verifying your eligibility. Don't
            worry, our support team will contact you to get this figured out! In
            the meantime, please hit 'Skip and come back to this later' to
            continue.
          </p>
        ) : (
          dobErrorCounter >= 1 && (
            <ErrorMessaging text="Hmm, something's not right. Please make sure that the required information you've entered matches what's on the file with the school/organization." />
          )
        )}
      </div>

      {/* Buttons */}
      <div className="flex w-full gap-2 sm:gap-4">
        <button
          type="button"
          className={`w-1/2 ${tertiaryButtonClass}`}
          disabled={isLoading}
          onClick={goBack}
        >
          <ChevronLeftIcon className="h-5 w-5" />
          Back
        </button>
        <button
          onClick={handleSubmit(submit)}
          type="button"
          className={`w-1/2 ${primaryButtonClass}`}
          disabled={
            !isValid || isLoading || dobErrorCounter >= 3 || dobErrorUnder18
          }
        >
          {isLoading ? (
            <>
              Loading <RefreshIcon className="loader h-5 w-5 text-white" />
            </>
          ) : (
            <>
              Next
              <ChevronRightIcon className="h-5 w-5 text-white" />
            </>
          )}
        </button>
      </div>

      {/* Skip */}
      {!isLoading && (
        <SkipAndComeBackLater
          handleClick={handleClickSkip}
          mixPanelData={{
            ...MIXPANEL_DATA,
            properties: {
              ...MIXPANEL_DATA.properties,
              skipped: true,
            },
          }}
        />
      )}
    </div>
  )
}

const ErrorMessaging = ({ text }) => (
  <div className="col-span-2 flex items-center gap-1">
    <div>
      <ExclamationCircleIcon
        className="h-5 w-5 text-red-500"
        aria-hidden="true"
      />
    </div>
    <p className="text-xs text-status-error">{text}</p>
  </div>
)

export default BuildProfileScreen
