import mixpanel from 'mixpanel-browser'
import { initMixpanel } from '../helpers/mixpanel'

initMixpanel()

export const MIXPANEL_EVENT = {
  PAGE_VIEW: 'Page View',
  ACCOUNT_CREATED: 'Account Created',
  ONBOARDING_STEP_COMPLETED: 'Onboarding Step Completed',
  THERAPIST_UPDATED: 'Therapist Updated',
  SESSION_BOOKED: 'Session Booked',
  INSURANCE_UPDATED: 'Insurance Updated',
  RESOURCE_VIEWED: 'Resource Viewed',
  RESOURCE_COMPLETED: 'Resource Completed',
  RESOURCE_SEARCH: 'Resource Search',
  COURSE_VIEWED: 'Course Viewed',
  COURSE_STARTED: 'Course Started',
  COURSE_COMPLETED: 'Course Completed',
  TASK_VIEWED: 'Task Viewed',
  ROSTER_PROFILE_VIEWED: 'Roster Profile Viewed',
  DOCUMENT_UPLOADED: 'Document Uploaded',
  FILE_DOWNLOADED: 'File Downloaded',
  SESSION_CANCELLED: 'Session Cancelled',
  SEARCH_COMPLETED: 'Search Completed',
  LIST_FILTERED: 'List Filtered',
  RESOURCE_BOOKMARKED: 'Resource Bookmarked',
  RESOURCE_RATED: 'Resource Rated',
  RESOURCE_PLAYED: 'Resource Played',
  CALENDAR_INVITE_ADDED: 'Calendar Invite Added',
  LIST_FILTER_RESET: 'List Filter Reset',
  SUPPORT_EMAIL: 'Support Email',
  THERAPIST_EMAIL: 'Therapist Email',
  ACCOUNT_UPDATED: 'Account Updated',
  MODAL_VIEWED: 'Modal Viewed',
  LINK_OPENED: 'Link Opened',
  DOT_ACADEMY_LINK: 'DotAcademy Link',
} as const

export type MixPanelTrackProps = {
  eventName: string
  properties: { [key: string]: any }
}

const trackMixPanel = (props: MixPanelTrackProps) =>
  mixpanel.track(props.eventName, {
    ...props.properties,
    platform: 'Family Care Hub',
  })

export default trackMixPanel
